/* src/components/query.css */

body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #000; /* Dark black background */
  color: #fff; /* White text */
}

div {
  margin: 20px;
}

h2 {
  color: #fff; /* White text color for headings */
  margin-bottom: 20px;
}

h3 {
  color: #fff; /* White text color for headings */
  margin-top: 20px;
}

h4 {
  color: #fff; /* White text color for headings */
  margin-top: 15px;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;
}

input[type="text"] {
  width: 80%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #333; /* Dark background for input */
  color: #fff; /* White text */
}

button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #555;
  color: white;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
}

button:hover {
  background-color: #666;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  background-color: #444; /* Dark background for list items */
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  color: #fff; /* White text for list items */
}

li:nth-child(even) {
  background-color: #555; /* Alternating background for even list items */
}
