body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #000; /* Dark black background */
  color: #fff; /* White text */
}

.app {
  width: 80%;
  margin: 0 auto;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  color: white;
  width: 100%;
  height: 50px;
  box-sizing: border-box; /* Include padding and border in the element's total width */
  position: fixed; /* Fix the header at the top */
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000; /* Ensure the header is above other elements */
  background-color: #2b2929;
  margin: 0cm;
}

.header .logo {
  font-size: 24px;
  font-weight: bold;
  color: #ddd;
  text-decoration: none;
}

.header .nav {
  display: flex;
  gap: 20px;
}

.header .nav a {
  text-decoration: none;
  color: white;
  font-size: 22px;
  transition: color 0.3s;
}

.header .nav a:hover {
  color: #ddd;
}

.content {
  padding: 80px 20px 20px; /* Add padding to prevent content from being hidden behind the fixed header */
}

.form-section {
  margin-bottom: 20px;
}

.form-section input,
.form-section textarea,
.form-section .button,
.form-section .file-input {
  width: 80%;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #333; /* Dark background for inputs */
  color: #fff; /* White text for inputs */
}

.form-section label {
  font-weight: bold;
}

.form-section textarea {
  resize: vertical;
}

.feature-box {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  text-align: center;
  transition: transform 0.3s, background-color 0.3s;
  color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #444; /* Dark background for feature boxes */
  margin-bottom: 20px;
  word-wrap: break-word;
}

.feature-box {
  background-color: rgba(71, 165, 237, 0.203);
}

.button {
  display: block;
  width: 20%;
  padding: 10px;
  margin: 10px auto;
  border: none;
  border-radius: 5px;
  background-color: #555555;
  color: white;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  font-size: 30px;
}

.button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.file-input {
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  border: none;
}

.chat-input {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0px;
}

.speech-input {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0px;
}
.output {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px; /* Space between the grid items */
  padding: 20px;
  justify-content: space-between; /* Distribute space between grid items horizontally */
  align-content: space-between;
}
