/* src/components/upload.css */

body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #000; /* Dark black background */
  color: #fff; /* White text */
}

div {
  margin: 20px;
}

h2 {
  color: #fff; /* White text color for headings */
  margin-bottom: 20px;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;
}

input[type="file"] {
  width: 80%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #333; /* Dark background for file input */
  color: #fff; /* White text */
}

button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #555;
  color: white;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
}

button:hover {
  background-color: #666;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

p {
  color: #fff; /* White text for message */
  margin-top: 20px;
}
